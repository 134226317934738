import {
    ROUTES,
    IndexRoute,
    Router
} from './router';
// import faker from 'faker';

const IMAGES_PATH = '/assets/images';

// Preload image assets preventing
// image flickering on page transitions
(_ => {
    const preloadImgs = [
        // '9page_1.png'
    ];
    const images = [];

    for (var i = 0; i < preloadImgs.length; i++) {
        images[i] = new Image(); // eslint-disable-line no-undef
        images[i].src = `${IMAGES_PATH}/${preloadImgs[i]}`;
    }
})();

/*
Keep in mind that order of routes is matter
*/
Router.on({
    ...IndexRoute(ROUTES.INDEX)
});

Router.notFound(_ => {
    Router.navigate(ROUTES.INDEX);
});

// ENTRY POINT
const resolveApp = () => {
    Router.resolve();
};

document.onload = resolveApp();
