let pageH = document.body.clientHeight / 2;

const getCoords = (elem) => {
    const box = elem.getBoundingClientRect();
    return {
        top: box.top,
        bottom: box.bottom + pageYOffset // eslint-disable-line no-undef
    };
};

const arrElem = ['.line3', '.line2', '.line5', '.scanner-device', '.manage-content__feature:nth-child(4)', '.manage-content__feature:nth-child(3)', '.manage-content__feature:nth-child(2)', '.manage-content__feature:nth-child(1)', '.instantly-content__feature:nth-child(1)', '.instantly-content__feature:nth-child(2)', '.instantly-content__feature:nth-child(3)', '.instantly-content__feature:nth-child(4)', '.instantly-content__feature:nth-child(5)', '.instantly-content__feature:nth-child(6)'];

const checkClass = () => {
    // eslint-disable-next-line one-var
    const subElem = document.querySelector('.instantly-device'),
        manageElem = document.querySelector('.manage .wrapper'),
        lineElem2 = document.querySelector('.line3'),
        lineElem3 = document.querySelector('.line4'),
        el = document.querySelector('.instantly-content__feature:nth-child(1)'),
        el3 = document.querySelector('.line5'),
        el4 = document.querySelector('.manage-content__feature'),
        el5 = document.querySelector('.manage-content__feature:nth-child(1)'),
        el6 = document.querySelector('.manage-content__feature:nth-child(2)'),
        el7 = document.querySelector('.manage-content__feature:nth-child(3)'),
        el8 = document.querySelector('.manage-content__feature:nth-child(4)');
    if (el.classList.contains('active')) {
        subElem.classList.add('active');
    } else {
        subElem.classList.remove('active');
    }
    if (el3.classList.contains('active')) {
        lineElem3.classList.add('noanim');
    } else {
        lineElem3.classList.remove('noanim');
    }
    if (el4.classList.contains('active1')) {
        lineElem2.classList.add('noanim');
    } else {
        lineElem2.classList.remove('noanim');
    }
    if (!el4.classList.contains('active')) {
        manageElem.className = 'wrapper';
    }
    if (el5.classList.contains('active')) {
        manageElem.className = 'wrapper active1';
    }
    if (el6.classList.contains('active')) {
        manageElem.className = 'wrapper active2';
    }
    if (el7.classList.contains('active')) {
        manageElem.className = 'wrapper active3';
    }
    if (el8.classList.contains('active')) {
        manageElem.className = 'wrapper active4';
    }
};

const setAnim = (data) => {
    for (let i = 0; i < data.length; i++) {
        const elem = document.querySelector(data[i]);
        if (pageH >= getCoords(elem).top) {
            elem.classList.add('active');
            checkClass();
        } else {
            elem.classList.remove('active');
        }
    }
};

export const init = () => {
    window.addEventListener('scroll', () => {
        setAnim(arrElem);
        const scrolled = window.pageYOffset || document.documentElement.scrollTop;
        if (scrolled >= document.documentElement.scrollHeight - document.documentElement.clientHeight) {
            document.querySelector('.scanner-device').classList.add('active');
            document.querySelector('.line5').classList.add('active');
            checkClass();
        }
    });

    window.addEventListener('resize', () => {
        pageH = document.body.clientHeight / 2;
    });
};
