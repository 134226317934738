import Router from './createRouter';
import { init } from '../utils';

export default path => ({
    [`${path}`]: {
        uses: () => {
            Router.updatePageLinks();
            gtag('event', 'Home Page Opened'); // eslint-disable-line no-undef
            init();
        },
        hooks: {
            before: done => {
                const home = document.querySelector('.home');
                home.removeAttribute('hidden');

                done();
            },
            leave: _ => {
                const home = document.querySelector('.home');
                home.setAttribute('hidden', true);
            }
        }
    }
});
