export default {
    INDEX: '',
    SIGNIN: 'signin',
    CREATEACCOUNT: 'createaccount',
    PLANS: 'plans',
    PRICES: 'prices',
    BILLING: 'billing',
    CONGRATS: 'congrats',
    LOGIN: 'login',
    UNSUBSCRIBE: 'unsubscribe',
    CANCEL: 'cancel',
    FORGOT: 'forgot',
    REST: 'reset'
};
